exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cadcam-tsx": () => import("./../../../src/pages/cadcam.tsx" /* webpackChunkName: "component---src-pages-cadcam-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-denture-tsx": () => import("./../../../src/pages/denture.tsx" /* webpackChunkName: "component---src-pages-denture-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-machines-tsx": () => import("./../../../src/pages/machines.tsx" /* webpackChunkName: "component---src-pages-machines-tsx" */),
  "component---src-pages-modelless-tsx": () => import("./../../../src/pages/modelless.tsx" /* webpackChunkName: "component---src-pages-modelless-tsx" */)
}

